<template>
<div>
    <div class="social_duty">
      <p class="btn">真心法律调解中心</p>
      <ul class="social_list">
        <li class="social_item flex_box" v-for="item in list" :key='item.id' @click="goDetails(item)">
          <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='fill' class="img" style="width: 240px; height: 150px"></el-image>
          <div class="time">
            <span>{{getMonth(item.createTime,1)}}</span>
            <p>{{getMonth(item.createTime,2)}}</p>
          </div>
          <p class="line"></p>
          <div class="flex1">
            <h3 class="title">{{item.socialTitle}}</h3>
            <p class="desc">{{item.subtitle}}</p>
          </div>
          <span class="iconfont icon-xiangyoujiantou"></span>
        </li>
      </ul>
      <pagination ref='pagination' :total='total' @currentChange='currentChange' v-if='total>0'></pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list:[],
      total:0,
      params:{
        pageNum:1,
        pageSize:10
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList() {
      this.$api.getZhenxinfltjzxByPage(this.params).then(res=>{
        this.list = res.rows
        this.total = res.total
      })
    },
    currentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    goDetails(item) {
      this.$router.push({
        path:'/socialDetails/'+item.id,
        query:{
          type:this.$route.query.type
        }
      })
    },
    getMonth(time,type) {
      if (!time) return ''
      let t = time.split(' ')[0]
      let arr = t.split('-')
      if (type == 1) {
        return `${arr[1]}/${arr[2]}`
      } else {
        return arr[0]
      }
    }
  }
}
</script>

<style lang='stylus' scoped>
@import '~@/assets/stylus/variable'
  .social_duty
    width: 1300px
    margin 30px auto 0
    .btn
      width: 170px
      height: 50px
      text-align center
      line-height 50px
      color #fff
      font-size 20px
      background: $theme-color
    .social_item
      padding 30px 30px 30px 0
      border-bottom: 1px dashed #bbb
      cursor pointer
      &:hover
        background: #ddd
        padding-left 30px
        .title
          color $theme-color
        .iconfont
          background: $theme-color
          border-color $theme-color
          color #fff
      .img
        margin-right 36px
      .time
        font-size 26px
        color #666
        line-height: 30px
        p
          font-size 18px
      .line
        width 1px
        height 160px
        background: #bbb
        margin 0 20px
      .title
        font-size 22px
        color #333333
        margin 15px 0
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
      .desc
        font-size 16px
        color #666666
        line-height: 30px
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      .iconfont
        width: 44px
        height: 44px
        border-radius 50%
        border 1px solid #bbb
        display: flex
        align-items: center
        justify-content: center
        margin-left: 50px
        font-size 24px
        color #999
</style>